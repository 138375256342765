'use strict';

const navToggleBtn = document.querySelector('.nav-toggle-btn'),
  headerElement = document.querySelector('#header');

navToggleBtn.addEventListener('click', function () {
  headerElement.classList.toggle('mob-menu-show');
  document.body.classList.toggle('prevent-scroll');
});

//sroll to
const scrollToLlink = document.querySelectorAll('.scroll-to'),
  headerTop = document.getElementById('header'),
  navToggle = document.querySelector('.nav-toggle-btn');

scrollToLlink.forEach(function (link) {
  link.addEventListener('click', function () {
    let dataSection = this.dataset.href;
    window.scroll({
      top: document.querySelector('' + dataSection + '').offsetTop,
      left: 0,
      behavior: 'smooth'
    });
    if (headerTop.classList.contains('mob-menu-show')) {
      headerTop.classList.remove('mob-menu-show');
      document.body.classList.remove('prevent-scroll');
    }
  });
});